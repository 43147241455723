.registro-de-ponto-button-wrap-mobile{
    display: none;
}


@media only screen and (max-width: 400px) {
    /* For mobile phones: */
    .registro-de-ponto-button-wrap-mobile{
        text-align: right;
        margin-top: 24px;
        display: block;
    }

    .registro-de-ponto-button-wrap{
        display: none;
    }

  }