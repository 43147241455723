.hover-pointer-gray:hover{
    cursor: pointer;
    background: #f8f8f8;
}

.Toastify__toast-container--top-right{
    top: 5rem!important;
}

.Toastify__toast-container--top-center{
    top: 5rem!important;
}