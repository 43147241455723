.flex-center{
    display: flex;
    justify-content: center;
}

.date{
    font-size: 1.0rem;
    margin-right: 0.5rem;
}
.hour{
    font-size: 1.5rem;
}

.div-webcam{
    border-radius: 8px;
    transition: .3s ease;
}
.col-webcam{
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
}

.button-punch-clock{
    padding: 10px 25px;
    border-radius: 8px;
    margin-bottom: 22px;
    transition: .3s ease;
}

.point-register-container{
    max-height: fit-content;
}
.point-register-container .card-body{
    padding: 2.25rem;
}
.point-register-container .card-footer{
    padding: 0.75rem 2.25rem;
}

@media only screen and (min-width: 1000px){
    .col-webcam, .button-punch-clock{
        max-width: 65%!important;
    }
    .main-container{
        max-width: 70%!important;
    }
}

@media only screen and (min-width: 1366px){
    .main-container{
        max-width: 40%!important;
    }
}